import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRootState } from "../store";

export interface IPromptType {
  isDisabled?: boolean;
  isOpen?: boolean;
}

const initialState: IPromptType = {
  isDisabled: false,
  isOpen: false,
};

const promptSlice = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    setPromptDisabled(
      state: IPromptType,
      action: PayloadAction<IPromptType | undefined>
    ) {
      state.isDisabled = action.payload?.isDisabled;
    },
    setPromptOpen(
      state: IPromptType,
      action: PayloadAction<IPromptType | undefined>
    ) {
      state.isOpen = action.payload?.isOpen;
    },
  },
});

export const promptDisabledSelector = (state: IRootState) => {
  return state?.prompt?.isDisabled;
};
export const promptOpenSelector = (state: IRootState) => {
  return state.prompt?.isOpen;
};

export const { reducer } = promptSlice;

export const { setPromptDisabled, setPromptOpen } = promptSlice.actions;

export default promptSlice;
