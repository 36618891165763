const CONSUMER_TRUST_INDEX = "CONSUMER_TRUST_INDEX";

export { CONSUMER_TRUST_INDEX };

export const PUBLIC_NAVIGATION: { [key: string]: string } = Object.freeze({
  consumerInit: "/guide",
});

export const PRIVATE_NAVIGATION = Object.freeze({
  profile: {
    edit: {
      activeSidebar: 1,
      path: "/",
    },
    bankLogin: {
      sideBarId: 0,
      path: "/bank-login",
    },
    bankConsent: {
      sideBarId: 0,
      path: "/bank-consent",
    },
    consumerView: {
      activeSidebar: 0,
      path: "/consumer-view",
    },
    consentConfirm: {
      activeSidebar: 0,
      path: "/consent-confirm",
    },
    consentConfirmScreen: {
      activeSidebar: 0,
      path: "/consent-confirm-status",
    },
  },
  config: {
    activeSidebar: 1,
    path: "/config",
  },
});

export const IMAGE_SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
