import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./slice/authSlice";
import { reducer as consumerReducer } from "./slice/consumerSlice";
import { reducer as promptReducer } from "./slice/promptSlice";
import { reducer as siteLoaderReducer } from "./slice/siteLoaderSlice";
import { reducer as tokenReducer } from "./slice/tokenSlice";

const rootReducer = combineReducers({
  siteLoader: siteLoaderReducer,
  prompt: promptReducer,
  auth: authReducer,
  token: tokenReducer,
  consumer: consumerReducer,
});

export default rootReducer;
