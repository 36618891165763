import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRootState } from "../store";

export interface IUserDetailsInterface {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string | null;
  mobile_number: string;
  image: string | null;
  loyalty_number: string | null;
  trudenty_id: string;
  total_score: number;
  risk_level: string;
  consent_status: string;
  request_timestamp: Date | string;
  yapily_user_id: string | null;
  consumer_type: string;
  attributes: [
    {
      id: number;
      name: string;
      score: string;
      description: string;
      recommended: string;
      config_type: string;
      sub_category: [
        {
          id: number;
          name: string;
          score: string;
          recommended: string;
          description: string;
        },
      ];
    },
  ];
}

export interface AuthSliceType {
  consumerDetails?: IUserDetailsInterface | null;
}

const initialState: AuthSliceType = {
  consumerDetails: null,
};

const consumerSlice = createSlice({
  name: "consumer",
  initialState,
  reducers: {
    clearConsumerData(state: AuthSliceType) {
      state.consumerDetails = null;
    },
    setConsumerDetails(
      state: AuthSliceType,
      action: PayloadAction<AuthSliceType | undefined>
    ) {
      state.consumerDetails = action.payload?.consumerDetails;
    },
  },
});

export const consumerSelector = (state: IRootState) => {
  return state?.consumer?.consumerDetails;
};
export const { reducer } = consumerSlice;

export const { setConsumerDetails, clearConsumerData } = consumerSlice.actions;

export default consumerSlice;
