import * as Yup from "yup";

export const ConsumerInitializeSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Enter valid Email.")
      .required("Email is required"),
    phoneNumber: Yup.string().nullable(),
    loyality_Id: Yup.string().nullable(),
    projectApiKey: Yup.string().required("Project API key is required"),
  });
};
