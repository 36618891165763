import React from "react";
import { Helmet } from "react-helmet";

interface MetaProps {
  title: string;
  description?: string;
}

const Meta: React.FC<MetaProps> = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
    </Helmet>
  );
};

export default Meta;
