import Routes from "./routes";

const App = () => {
  // const isAuthenticated = localStorage.getItem('isAuthenticated')
  return (
    // <CookieContextProvider>
    <Routes />
    // </CookieContextProvider>
  );
};

export default App;
