import React, { ButtonHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";

// Assets
import { ReactComponent as BackIcon } from "@icons/back-arrow.svg";
import { getClassNames } from "../utils/helpers";

interface BackArrowProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  className?: string;
  isNavigate?: boolean;
}

const BackArrow: React.FC<BackArrowProps> = ({
  onClick,
  className,
  isNavigate = true,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={getClassNames(
        "bg-grey-stroke-primary rounded-s-lg w-7 h-[26px] flex items-center justify-center cursor-pointer",
        className
      )}
      onClick={
        onClick
          ? onClick
          : () => {
              if (isNavigate) {
                navigate(-1);
              }
            }
      }
    >
      <BackIcon />
    </div>
  );
};

export default BackArrow;
