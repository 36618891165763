import { useField } from "formik";

// ** component **
import ErrorMessage from "./ErrorMessage";

// ** type **
import { InputProps } from "./types/index";

const InputField = ({
  name,
  value,
  label,
  id,
  placeholder,
  className,
  isDisabled = false,
  type,
  onBlur,
  isNotCompulsory,
  icon,
  parentClass,
  labelClass,
  options,
  isCompulsory,
  showPasswordSecurity,
}: InputProps) => {
  const [field] = useField(name);

  const renderSecurityIndicator = () => {
    let countCompleted = 0;
    const resp = [];
    if ((value ?? field?.value)?.length > 0) {
      const password = value ?? field?.value;

      if (password.length >= 8) {
        countCompleted++;
      }

      // Check if password contains at least one upper case letter
      if (/[A-Z]/.test(password)) {
        countCompleted++;
      }

      // Check if password contains at least one lower case letter
      if (/[a-z]/.test(password)) {
        countCompleted++;
      }

      // Check if password contains at least one number
      if (/\d/.test(password)) {
        countCompleted++;
      }
    }
    for (let i = 0; i < countCompleted; i++) {
      resp.push(
        <span
          className={`border-2 w-1/4 rounded-sm ${countCompleted === 4 ? "text-green-alert" : "text-red-alert"}`}
        ></span>
      );
    }
    return resp;
  };

  return (
    <div className={`w-full relative  ${parentClass ?? ""}`}>
      {label && (
        <div className="flex justify-between">
          <label
            className={`text-base font-medium leading-5 -tracking-0.2 text-grey-content-secondary mb-3 block justify-between ${labelClass ?? ""}`}
            htmlFor={name}
          >
            {label}
            {isCompulsory && <span> *</span>}
          </label>
          {isNotCompulsory && (
            <label className="text-grey-content-tertiary">Optional</label>
          )}
        </div>
      )}
      <input
        className={`block w-full bg-white2  -tracking-0.4 placeholder:text-grey-content-tertiary text-body-black font-medium px-5 py-3  border border-solid border-grey-stroke-primary rounded focus:outline-0  transition-all duration-300 leading-5 !p-3 md:!p-4 ${
          className ?? ""
        }`}
        id={id}
        placeholder={placeholder ?? ""}
        type={type}
        {...field}
        name={name}
        value={value ?? field.value ?? ""}
        onBlur={onBlur}
        disabled={isDisabled ?? false}
        autoComplete="new-password"
        {...options}
      />
      {showPasswordSecurity && (
        <div className="mt-1 w-full flex gap-2 text-red-alert">
          {renderSecurityIndicator()}
        </div>
      )}
      {icon && <span className="absolute top-[2.85rem] right-8">{icon}</span>}
      <ErrorMessage name={name} />
    </div>
  );
};

export default InputField;
