import { ReactComponent as Notification } from "@/assets/icons/notification.svg";
import { authSelector } from "@/redux/slice/authSlice";
import { useSelector } from "react-redux";
import ScoreBubble from "../../pages/consumerTrustIndex/components/ScoreBubble";
import BackArrow from "../BackArrow";
import Meta from "../Meta";
const SiteHeader = ({
  header,
  showBackButton = false,
  isDisableCTI = false,
}: {
  header?: string;
  showBackButton?: boolean;
  isDisableCTI?: boolean;
}) => {
  const userDetails = useSelector(authSelector);
  return (
    <div
      className={`flex items-center w-full py-4 px-9 ${isDisableCTI ? "" : ""}`}
    >
      <Meta
        title={`${header ? header + " - " : ""}Trudenty Trust Network Console`}
      />
      <div className="grow flex gap-4 items-center">
        {showBackButton ? <BackArrow /> : null}
        <h2 className="text-black text-xl2 font-medium">{header}</h2>
      </div>
      {!isDisableCTI && (
        <div className="self-end flex gap-8 items-center">
          <Notification />
          {userDetails?.total_score !== undefined ? (
            <ScoreBubble
              {...{
                score: userDetails?.total_score ?? 0,
                inactive: false,
                risk_level: userDetails?.risk_level ?? "LOW_RISK_CONSUMER",
                className: " !w-11 !h-11 text-xl",
              }}
            />
          ) : (
            <div className="bg-stroke-secondary w-11 h-11 rounded-full flex justify-center items-center">
              {" "}
              -{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SiteHeader;
