import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRootState } from "../store";

export interface ITokenType {
  access_token: string | null;
}

const initialState: ITokenType = {
  access_token: null,
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken(state: ITokenType, action: PayloadAction<ITokenType>) {
      state.access_token = action.payload.access_token;
    },
    clearToken(state: ITokenType) {
      state.access_token = null;
    },
  },
});

export const tokenSelector = (state: IRootState) => {
  return state?.token?.access_token;
};
export const { reducer } = tokenSlice;

export const { setToken, clearToken } = tokenSlice.actions;

export default tokenSlice;
