import { getClassNames, getRiskLevelColor } from "@/utils/helpers";

const ScoreBubble = ({
  score,
  inactive,
  risk_level,
  className,
}: {
  score: number;
  inactive?: boolean;
  risk_level: string;
  className?: string;
}) => {
  return (
    <div
      className={getClassNames(
        `py-4 px-6 bg-grey text-[3.5rem] flex items-center justify-center w-[110px] h-[110px] rounded-full font-semibold ${className}`,
        getRiskLevelColor(risk_level, inactive)
      )}
    >
      {score}
    </div>
  );
};

export default ScoreBubble;
