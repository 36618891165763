import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ISiteLoaderType {
  isLoader?: boolean;
  isTransparent?: boolean;
  header?: string | null;
  description?: string | null;
}

const initialState: ISiteLoaderType = {
  isLoader: false,
  isTransparent: false,
  header: null,
  description: null,
};

const siteLoaderSlice = createSlice({
  name: "siteLoader",
  initialState,
  reducers: {
    showLoader(
      state: ISiteLoaderType,
      action: PayloadAction<ISiteLoaderType | undefined>
    ) {
      state.isLoader = true;
      state.isTransparent = action?.payload?.isTransparent;
      state.header = action?.payload?.header;
      state.description = action?.payload?.description;
    },
    hideLoader(state: ISiteLoaderType) {
      state.isLoader = false;
      state.isTransparent = false;
      state.header = null;
      state.description = null;
    },
  },
});

export const siteLoaderSelector = (state: { siteLoader: ISiteLoaderType }) => {
  return state.siteLoader;
};

export const { reducer } = siteLoaderSlice;

export const { showLoader, hideLoader } = siteLoaderSlice.actions;

export default siteLoaderSlice;
