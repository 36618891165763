import { Transition } from "@headlessui/react";
import { ReactComponent as TickActive } from "@icons/tick-active.svg";
import { ReactComponent as TickInactive } from "@icons/tick-inactive.svg";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../assets/icons/close-white.svg";
import { ReactComponent as PopoverRightArrow } from "../assets/icons/popOverRightArrow.svg";
import { ReactComponent as PopoverLeftArrow } from "../assets/icons/popoverLeftArrow.svg";
import {
  promptDisabledSelector,
  promptOpenSelector,
  setPromptDisabled,
  setPromptOpen,
} from "../redux/slice/promptSlice";
import { getClassNames } from "../utils/helpers";

type DemoGuidePopoverProps = {
  title: string;
  body?: string;
  maxWidth?: string;
  topOffset: number;
  leftOffset: number;
  arrowPosition?: "top" | "bottom" | "left" | "right";
  noArrow?: boolean;
  el: () => HTMLElement | null;
  children?: React.ReactNode;
  setPopUp?: React.Dispatch<React.SetStateAction<number>>;
  setPrev?: boolean;
  setNext?: boolean;
  popUp?: number;
};

const DemoGuidePopover: React.FC<DemoGuidePopoverProps> = ({
  title,
  body,
  maxWidth = "max-w-[350px]",
  topOffset = 100,
  leftOffset = 100,
  arrowPosition = "top",
  noArrow = false,
  el,
  children,
  setPopUp,
  setPrev,
  setNext,
  popUp = 0,
}) => {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });
  const promptDisabled = useSelector(promptDisabledSelector);
  const promptOpen = useSelector(promptOpenSelector);

  useEffect(() => {
    const targetElement = el();

    if (targetElement instanceof HTMLElement) {
      // Create a new ResizeObserver instance
      const resizeObserver = new ResizeObserver(() => {
        updatePopoverPosition();
      });

      // Start observing the target element
      resizeObserver.observe(targetElement);

      // Create a new MutationObserver instance
      const mutationObserver = new MutationObserver(() => {
        updatePopoverPosition();
      });

      // Start observing the target element for configured mutations
      mutationObserver.observe(targetElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
      };
    }
  }, [el]);

  // Function to update the popover's position
  function updatePopoverPosition() {
    const itop = el()?.getBoundingClientRect().top ?? 0;
    const ileft = el()?.getBoundingClientRect().left ?? 0;

    setCoordinates({ top: itop + topOffset, left: ileft + leftOffset });
  }

  const arrowPositions = {
    top: {
      top: "-5px",
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
    },
    bottom: {
      bottom: "-5px",
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",
    },
    left: {
      left: "-5px",
      top: "50%",
      transform: "translateY(-50%) rotate(45deg)",
    },
    right: {
      right: "-5px",
      top: "50%",
      transform: "translateY(-50%) rotate(45deg)",
    },
  };

  const disablePopup = () => {
    dispatch(setPromptOpen({ isOpen: false }));
    if (check) {
      dispatch(setPromptDisabled({ isDisabled: true }));
    }
  };

  return !promptDisabled ? (
    // return !(isAuthenticated && promptDisabled) ? (
    <Transition appear show={promptOpen} as={Fragment}>
      <div className="relative z-50 font-medium">
        <div
          className=" w-full overflow-y-auto"
          style={{ top: `${coordinates.top}px`, left: `${coordinates.left}px` }}
        >
          <div
            className={getClassNames(
              "absolute w-3 h-3 bg-body-black rounded-sm rotate-45",
              noArrow ? "hidden" : ""
            )}
            style={{ ...arrowPositions[arrowPosition] }}
          />
          <div className=" flex container-solutions">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={getClassNames(
                  `w-full transform rounded-lg bg-body-black 
                  shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-6`,
                  maxWidth
                )}
              >
                <div className="flex items-center gap-x-2 justify-between">
                  <h6 className="header6 text-white">{title}</h6>
                  <CloseIcon
                    className="fill-white cursor-pointer"
                    onClick={() => {
                      disablePopup();
                    }}
                  />
                </div>
                <p
                  className="text-grey-content-tertiary mt-3"
                  dangerouslySetInnerHTML={{ __html: String(body) }}
                />
                <div>{children}</div>
                <div className="flex items-center justify-between mt-4">
                  {popUp > 0 && (
                    <div className="flex items-center gap-x-0.5">
                      <PopoverLeftArrow
                        className={getClassNames(
                          setPrev ? "cursor-pointer" : "opacity-50"
                        )}
                        onClick={
                          setPrev
                            ? () => {
                                popUp > 0 && setPopUp && setPopUp(popUp - 1);
                              }
                            : () => {}
                        }
                      />
                      <PopoverRightArrow
                        className={getClassNames(
                          setNext ? "cursor-pointer" : "opacity-50"
                        )}
                        onClick={
                          setNext
                            ? () => {
                                popUp > 0 && setPopUp && setPopUp(popUp + 1);
                              }
                            : () => {}
                        }
                      />
                    </div>
                  )}
                  <div className="flex items-center gap-x-1">
                    {check ? (
                      <TickActive
                        onClick={() => setCheck(false)}
                        className="cursor-pointer"
                      />
                    ) : (
                      <TickInactive
                        onClick={() => setCheck(true)}
                        className="cursor-pointer"
                      />
                    )}

                    <span className="text-grey-content-quaternary">
                      Don’t show again
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>
  ) : (
    <></>
  );
};

export default DemoGuidePopover;
